import React, { useState, useRef } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { redirect, useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { click } from '@testing-library/user-event/dist/click';
import { toBeEmptyDOMElement } from '@testing-library/jest-dom/matchers';



const ContactPage = () => {

    const navigate = useNavigate();
    const contact = () => navigate('/contact');

    const navigater = useNavigate();
    const home = () => navigater('/');

    
    function handleClick() {
        if(name == "" && email == "" || sub == "" || msg == ""){
        
            alert("Fill the empty field")
    }
    else{
        alert("Thank You\nData Submited")
        
    }
}

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8dco0ew', 'template_qx9wykf', form.current, 'MkVD3jNPUUCCfRqlE')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sub, setSub] = useState('');
    const [msg, setMsg] = useState('');


    const reset = () => {
        setName("");
        setEmail("");
        setSub("");
        setMsg("");
        document.getElementById("showMsg").style.display="none";
        document.getElementById("errorMsg").style.display ="none";
    }

    let fname = document.getElementById('name');
    let femail = document.getElementById('email');
    let fsubj = document.getElementById('subject');
    let fmsg = document.getElementById('message');
   


    return (
        <div>
            <Navbar />
            <div className='d-none d-lg-block'>
                <div id='header' className="container-fluid pt-5 bg-dark hero-header mb-5">
                    <div className="container pt-5">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12 align-self-center text-center text-lg-start mb-lg-5" style={{ paddingTop: "50px", paddingBottom: "250px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight"> <span style={{ color: "orangered" }}>CONTACT</span> US</h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-lg-none'>
                <div id='header' className="container-fluid py-5 bg-dark ">
                    <div className="container ">
                        <div className="row g-5 ">
                            <div className="col-lg-12 align-self-center text-center text-lg-start mb-lg-5">
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight"> <span style={{ color: "orangered" }}>CONTACT</span> US</h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" >
                        <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Contact Us</div>
                        <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="wow fadeIn" data-wow-delay="0.3s">

                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" name="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id='name' placeholder="Your Name" required />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" name="Email" id='email' className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your Email" required />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" name="Subject" id='subject' className="form-control" value={sub} onChange={(e) => setSub(e.target.value)} placeholder="Subject" required />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" id='message' name="Message" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder="Leave a message here" style={{ height: "150px" }} required></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <input type="hidden" name="_template" value="table" />

                                        <p id='showMsg' style={{display:"none"}}>Send Successfully</p>
                                        <p id='errorMsg' style={{display:"none"}}>Fill Empty Field</p>


                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button className="btn btn-orange w-100 py-3" id='btn' type="submit" onClick={handleClick}>Send Message</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button className="btn btn-orange w-100 py-3" id='btn' onClick={reset} type="submit" >Reset</button>
                                                </div>
                                                </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='contact' className="container-fluid bg-orange newsletter py-5" >
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
                            <img className="img-fluid" src="img/newsletter.png" alt=".." />
                        </div>
                        <div className="col-md-7 py-5 newsletter-text card1 wow fadeIn" data-wow-delay="0.5s">
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Contact Us</div>
                            <h1 className="text-white mb-4">Sync Smarter with HexSync</h1>
                            <p className="text-light"><i className="fas fa-envelope me-3"></i>info@hexsync.com</p>
                            <p className="text-light"><i className="fa fa-phone-alt me-3 text-light"></i>+92 347 3994589</p>
                            <p className="text-light"><i className="fa fa-map-marker-alt me-3"></i>B-50, Karsaz Service Road West, KDA Scheme #1, Karachi</p>
                            <div className="position-relative w-100 mt-3 mb-2">
                                <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="email"
                                    placeholder="Enter Your Email" style={{ height: "48px" }} />
                                <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    className="fa fa-paper-plane text-primary fs-4"></i></button>
                            </div>
                            <small className="text-white-50"></small>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactPage