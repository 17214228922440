import React from 'react'
import logo from '../img/logo/Logo For Header.png'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const index = () => navigate('/');
  const about = () => navigate('/about');
  const service = () => navigate('/service');
  const ourTeam = () => navigate('/ourteam');
  const contact = () => navigate('/contact');
    return (
        <div>
            <div className="container-fluid pb-2 bg-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-dark p-0">
                        <a onClick={index} href={index} className="navbar-brand">
                            <img src={logo} style={{width:"150px", height:"89"}} alt='Logo'/>
                        </a>
                        <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse" >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto ">
                                <a onClick={index} href={index} className="nav-item nav-link">Home</a>
                                <a onClick={about}  href={about} className="nav-item nav-link">About</a>
                                <a onClick={contact} href={contact} className="nav-item nav-link">Contact</a>
                                <a onClick={service} href={service} className="nav-item nav-link">Services</a>
                                <a onClick={ourTeam} href={ourTeam} className="nav-item nav-link">Our Team</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <a href="#" className="btn btn-lg btn-orange btn-lg-square back-to-top pt-2 " aria-label='Scroll To Top'><i className="bi bi-arrow-up"></i></a>
        </div>
    )
}

export default Navbar 