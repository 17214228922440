import React from 'react'

import { useNavigate } from 'react-router-dom';

const OurTeam = () => {
    const navigate = useNavigate();
    const ourteam = () => navigate('/ourteam');
  return (
    <div>
      <div id='team' className="container-fluid py-5">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Our Team</div>
                    <h1 className="mb-4">Meet Our Experienced Team Members</h1>
                    <p className="mb-4">Team hexSync: We Code Your Vision into Reality</p>
                    <a className="btn btn-orange rounded-pill px-4" onClick={ourteam}>Read More</a>
                </div>
                <div className="col-lg-7">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn " data-wow-delay="0.1s">
                                    <div className="team-item bg-light text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/OsamaAli.jpg" alt=""/>
                                        <h5 className="mb-0">Syed Osama Ali</h5>
                                        <small>Founder & CEO</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/cvengg/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="team-item bg-light text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/Faseehullah.jpg" alt=""/>
                                        <h5 className="mb-0">Muhammad Faseeh Ullah</h5>
                                        <small>Director</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/faseehullah/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-md-4 mt-5">
                            <div className="row g-4 mt-5">
                                <div className="col-12 wow fadeIn mt-5" data-wow-delay="0.3s">
                                    <div className="team-item bg-light text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/umairsiddiqui.jpg" alt=""/>
                                        <h5 className="mb-0">Umair Siddiqui</h5>
                                        <small>Co Founder</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square ln-orange m-1" href="https://www.linkedin.com/in/muhammad-umair-siddiqui-bb7108b4/" target='_blank'><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default OurTeam