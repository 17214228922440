import React from 'react'
import { useNavigate } from 'react-router-dom';
import microsoftLogo from '../img/logo/Sliderformob.jpg'

const Header = () => {

        const navigate = useNavigate();
      const contact = () => navigate('/contact');
    return (
        <div>
            <div className="d-none d-lg-block postion-absolute">
                <video className='w-100'
                    id='myVideo'
                    playsInline
                    autoPlay
                    muted
                    preload="none"
                    loop>
                    <source src={require('../video/Hexsync website banner.mp4')} />
                    
                </video>
                
            </div>

            <div className="d-lg-none">
            <div id='header' className=" img-fluid">
        
                    {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                    <h1 className="display-4 text-white mb-4 animated slideInRight">FORGING FUTURE IN OUR <span style={{color:"orangered"}}>HIVE</span></h1>                    
                    <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a> */}
                    <img className="img-fluid" src={microsoftLogo}  alt=""/>
                </div>
                

            </div>


            {/* */}
        </div>
    )
}

export default Header