import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';

const AboutUsPage = () => {
    
    const navigate = useNavigate();
    const contact = () => navigate('/contact');
    return (
        <div>
            <Navbar />
            <div className='d-none d-lg-block'>
                <div id='header' className="container-fluid pt-5 bg-dark hero-header mb-5 ">
                    <div className="container pt-5">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12 align-self-center text-center text-lg-start mb-lg-5" style={{ paddingTop: "50px", paddingBottom: "250px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight"> <span style={{ color: "orangered" }}>ABOUT</span> US</h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='d-lg-none'>
                <div id='header' className="container-fluid py-5 bg-dark">
                    <div className="container ">
                        <div className="row g-5 " >
                            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight"> <span style={{ color: "orangered" }}>ABOUT</span> US</h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>


            <div id="about" className="container-fluid py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-4 wow fadeIn" >
                            <div className="">
                                <img className="img-fluid" src="img/technologies.jpeg" />
                            </div>
                        </div>
                        <div className="col-lg-8 wow fadeIn " data-wow-delay="0.5s">
                            <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">About Us</div>
                            <h1 className="mb-4">HexSync: Unleashing Software Solutions</h1>
                            <p className="mb-4 text-justify">Welcome to HexSync, where innovation meets excellence in software development. We are a large-scale software consulting company renowned for harnessing the best resources in the country to deliver cutting-edge solutions that empower businesses to thrive in the digital age. At HexSync, our journey is fueled by innovation, dedication, and a relentless pursuit of excellence in the ever-evolving technology landscape. Since our inception, we have been fully committed to transforming businesses worldwide by providing exceptional software services. With a solid foundation built on years of experience, we have successfully delivered comprehensive solutions to numerous clients across various industries.</p>
                        </div>
                    </div>
                    <div className="row mt-3 ">
                        <div className="col-lg-6 wow fadeIn text-justify" data-wow-delay="0.5s">
                            <h2>Vision</h2>
                            <p>
                                At HexSync, we envision a future where businesses of all sizes harness the full potential of technology to achieve unparalleled success. Our vision is to be the foremost guiding light in the realm of software consulting, driving innovation, and empowering organizations to thrive in the digital age. At Hexysnc, our vision is not just a destination; it's a journey. We invite you to join us on this exciting voyage toward a future where technology and innovation create endless possibilities for businesses around the world.
                            </p>
                        </div>
                        <div className="col-lg-6 wow fadeIn text-justify" data-wow-delay="0.5s">
                            <h2>Mission Statement</h2>
                            <p>
                                Our mission is to empower businesses with strategic guidance and technological expertise, enabling them to thrive and lead in an ever-evolving digital landscape. At HexSync, our mission is more than a statement; it's a commitment. We are here to guide, support, and empower businesses as they navigate the dynamic digital landscape, and we are passionate about helping them achieve their goals.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6 wow text-justify fadeIn" data-wow-delay="0.5s">
                            <h2>Our Journey</h2>
                            <p>
                                HexSync embarked on a journey fueled by a singular vision: to bridge the gap between businesses and technology excellence. Since our inception, we have relentlessly pursued this vision, making us a premier choice for organizations seeking transformative solutions.
                            </p>
                        </div>
                        <div className="col-lg-6 wow text-justify fadeIn" data-wow-delay="0.5s">
                            <h2>Our Expertise</h2>
                            <p>
                                What sets us apart is our relentless commitment to excellence. We have assembled a team of some of the brightest minds and experienced professionals in the industry. Our consultants, engineers, and strategists possess a wealth of knowledge and expertise, enabling us to deliver top-tier consulting services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="container-fluid bg-orange feature-white pt-5 ">
                <div className="container">
                    <div className="row g-3 align-items-center ">
                        <div className='col-lg-7 text-justify'>
                        <h2 className='text-light '><span style={{ color: "#242424" }}>HexSync</span> Unleashing Software Solutions</h2>
                        <p className='text-light ' style={{fontSize:"1rem",fontWeight:"300"}}>
                            Welcome to HexSync, where innovation meets excellence in software development. We are a large-scale software consulting company renowned for harnessing the best resources in the country to deliver cutting-edge solutions that empower businesses to thrive in the digital age. At HexSync, our journey is fueled by innovation, dedication, and a relentless pursuit of excellence in the ever-evolving technology landscape. Since our inception, we have been fully committed to transforming businesses worldwide by providing exceptional software services. With a solid foundation built on years of experience, we have successfully delivered comprehensive solutions to numerous clients across various industries.
                        </p>
                        </div>
                        </div>
                        <div className="row g-3 pt-3 pb-5" >
                        <div className='col-lg-7 text-justify'>
                        <h3 className='text-light ' style={{textAlign:"start"}}>Partner with HexSync</h3>
                        <p  className='text-light'>
                            At HexSync, we are more than a software consulting company; we are your strategic advisors in the digital age. Whether you are navigating complex technological challenges or seeking to unlock new opportunities, we have the resources and expertise to guide you toward success.
                            <br />
                            <br />
                            

                            Join us on this transformative journey, where technology meets innovation, and together, we can shape a brighter digital future for your business.
                            <br />
                            <br />
                            Reach out to us today to discuss your consulting needs, and let's embark on this journey of technological excellence together.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default AboutUsPage