import React from 'react'
import { useNavigate } from 'react-router-dom';
import video from '../video/DnaVideo.mp4'

const AboutUs = () => {

    const navigate = useNavigate();
    const contact = () => navigate('/about');
    return (
    <div>
        <div className='container-fluid py-5'>
            <div class="row align-items-center" style={{paddingRight:"0px",paddingLeft:"0px"}}>
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.1s" style={{width:"100%",paddingRight:"1px",paddingLeft:"1px"}}>
                    <div style={{margin:"20px"}}>
                        <p className="btn btn-sm border rounded-pill text-orange px-3" >About Us</p>
                    </div>
                    <div style={{width:"100%",paddingRight:"1px",paddingLeft:"1px"}}>
                    <video
                        style={{ width: "100%" }}
                        playsInline autoPlay muted preload="none" loop>
                        <source src={video} />
                    </video>
                    </div>
                    <div>
                        <img className="img-fluid" style={{ backgroundColor: "transparent" }} src="img/Languages-icons.png" />
                    </div>
                    <div style={{margin:"20px"}}>
                        <a className="btn btn-orange rounded-pill px-4 me-3" onClick={contact} target='_blank'>Read More</a>
                    </div>
                </div>
            </div>
        </div>
                
    </div>
        


    )
}

export default AboutUs


//     < div id = "about" className = "container-fluid  py-5" style = {{ paddingRight: "0px", paddingLeft: "0px" }}>
//         <div className="container py-5">
            
//         </div> 
// </div >