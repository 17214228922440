import React from 'react'
import { useNavigate } from 'react-router-dom';
import microsoftLogo from '../img/logo/Microsoft Dynamic 365.svg'
import azureLogo from '../img/logo/Microsoft_Azure.svg.png'
import salesForceLogo from '../img/logo/salesforce.svg'
import SapLogo from '../img/logo/sap.svg'

const OurServices = () => {

    const navigate = useNavigate();
    const service = () => navigate('/service');
    return (
        <div>
            <div className="container-fluid bg-light py-5" id='services'>
                <div className='d-none d-lg-block'>
                    <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-orange  mb-1">Our Services</div>
                            <h3 className="mb-1 text-start">Elevate Your Vision with HexSync: Where Innovation Meets Excellence</h3>
                        </div>
                        <div className="col-lg-12">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-10 wow fadeIn mb-1" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={microsoftLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3 px-1">Microsoft Dynamics 365</h5>
                                                <p className=' px-1' style={{fontSize:"15px"}}>Unlock the Power of Personalized Resource Management and Customer Engagement with Microsoft Dynamics 365</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>

                                        <div className="col-10 wow fadeIn " data-wow-delay="0.1s" style={{ marginLeft: "180px" }}>
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={azureLogo} style={{ width: "120px", height: "50px" }} />
                                                </div>
                                                <h5 className="service-heading mb-3 px-2" >Azure</h5>
                                                <p className=' px-2'>Build, innovate, and scale on the most trusted cloud platform with Azure.</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="row g-4">
                                        <div className="col-10 wow fadeIn mb-1" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={SapLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3  px-2">SAP</h5>
                                                <p className=' px-2'>Rise with SAP: Transform your business process through your business data.</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>
                                        <div className="col-10 wow fadeIn" data-wow-delay="0.1s" style={{ marginLeft: "180px" }}>
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={salesForceLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3  px-2">SalesForce</h5>
                                                <p className=' px-2'>Empower Your Business with Salesforce CRM: Elevate Your Customer Relationships</p>
                                                <a className="btn px-3 mt-auto mx-auto" onClick={service}>Read More</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className='d-lg-none'>
                    <div className="container ">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Our Services</div>
                            <h1 className="mb-4">Elevate Your Vision with HexSync: Where Innovation Meets Excellence</h1>
                        </div>
                        <div className="col-lg-12">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-10 wow fadeIn m-5" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={microsoftLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3 px-2">Microsoft Dynamics 365</h5>
                                                <p className=' px-2'>Unlock the Power of Personalized Resource Management and Customer Engagement with Microsoft Dynamics 365</p>
                                                <a className="btn px-3 mt-auto mx-auto" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                            </div>
                                        </div>

                                        <div className="col-10 wow fadeIn mb-5 mx-5" data-wow-delay="0.1s" >
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={azureLogo} style={{ width: "120px", height: "50px" }} />
                                                </div>
                                                <h5 className="service-heading mb-3 px-2" >Azure</h5>
                                                <p className=' px-2'>Build, innovate, and scale on the most trusted cloud platform with Azure.</p>
                                                <a className="btn px-3 mt-auto mx-auto" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="row g-4">

                                        <div className="col-10 wow fadeIn m-5" data-wow-delay="0.1s">
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={SapLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3  px-2">SAP</h5>
                                                <p className=' px-2'>Rise with SAP: Transform your business process through your business data.</p>
                                                <a className="btn px-3 mt-auto mx-auto" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                            </div>
                                        </div>
                                        <div className="col-10 wow fadeIn m-5" data-wow-delay="0.1s" >
                                            <div className="service-item d-flex flex-column justify-content-center text-center ">
                                                <div className="service-icon btn-square">
                                                    <img src={salesForceLogo} />
                                                </div>
                                                <h5 className="service-heading mb-3  px-2">SalesForce</h5>
                                                <p className=' px-2'>Empower Your Business with Salesforce CRM: Elevate Your Customer Relationships</p>
                                                <a className="btn px-3 mt-auto mx-auto" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurServices  