import React from 'react'
import logo from '../img/logo/Logo For Header.png'
import microsoft from '../img/logo/Microsoft Partner White.png'

const Footer = () => {
    return (
        <div>
            <div className="container-fluid bg-dark text-white-50 footer pt-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-8 col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <a href="#" className="d-inline-block mb-3">
                                <img src={logo} style={{ width: "200px", height: "89" }} />
                            </a>
                            <p className="mb-0 text-justify">Join us on this transformative journey, where technology meets innovation, and together, we can shape a brighter digital future for your business.</p>
                            <img src={microsoft} style={{ marginTop: "20px" }} />
                        </div>
                        <div className="col-md-4 col-lg-6 wow fadeIn " style={{ float: "right" }} data-wow-delay="0.3s">
                            <h4 className="text-white mb-4">Get In Touch</h4>
                            <h5 style={{ color: "white" }}>Pakistan</h5>
                            <p className='pBorder'><i className="text-orange fa fa-map-marker-alt me-3" ></i>B-50, Karsaz Service Road West, KDA Scheme #1, Karachi</p>
                            <p><i className="text-orange fa fa-phone-alt me-3"></i>+92 347 3994589</p>
                            <h5 style={{ color: "white" }}>UAE</h5>
                            <p><i className="text-orange fa fa-map-marker-alt me-3"></i>Office 85, Baynunah Tower 2, Al Hisn, Abu Dhabi</p>
                            <p><i className="text-orange fa fa-phone-alt me-3"></i>+971 559 286 154</p>
                            <h5 style={{ color: "white" }}>USA</h5>
                            <p><i className="text-orange fa fa-map-marker-alt me-3"></i>190 HOOVER RD YONKERS, NY 10710</p>
                            
                                
                            <div className="copyright1"></div>
                            <p><i className="text-orange fas fa-envelope me-3"></i>info@hexsync.com</p>
                            {/* <p><i className="fa fa-envelope me-3"></i>info@example.com</p> */}
                            <div className='col-sm-2'>

                            </div>
                            <div className="d-flex ">
                                <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.facebook.com/Hexsync" target='_blank'><i className="fab fa-facebook-f" ></i></a>
                                <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.instagram.com/hexsync" target='_blank'><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-orange-dark btn-square me-3" href="https://www.linkedin.com/company/hexsync" target='_blank'><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-outline-orange-dark btn-square" href="https://www.youtube.com/channel/UCv-Yd9JRkkVkTagNmemHiEw" target='_blank'><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container wow fadeIn" data-wow-delay="0.1s">
                    <div className="copyright">
                        <div className="row">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer