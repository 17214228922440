import React from 'react'
import WCUImage from '../img/logo/WCU-image.png'
import UpResorceImage from '../img/logo/resource.png'
import ClientCentricImage from '../img/logo/target-audience.png'
import InovationImage from '../img/logo/rocket.png'
import ProvenTrackImage from '../img/logo/track.png'

const WhyChooseUs = () => {
    return (

        <div>

            <div className='d-none d-lg-block'>
                <div className="container-fluid bg-dark feature-dot py-5" >
                    <div className='container' style={{ paddingLeft: "0px" }}>
                        <div className="row" style={{ paddingLeft: "0px", marginLeft: "-80px", padding: "40px 0px 40px 0px" }}>
                            <div className="col-lg-9 align-self-center card1 wow fadeIn" data-wow-delay="0.3s" style={{ padding: "10px 10px 10px 10px" }}>
                                <div className="btn btn-sm border rounded-pill text-orange px-3 my-3">Why Choose Us</div>
                                <h1 className="text-white mb-4 "><span style={{ color: "orangered" }}>HexSync:</span> Your Trusted IT Partner</h1>
                                <p className="text-light mb-4 text-justify pe-3">At HexSync, we are more than just an IT service provider; we are your dedicated technology partner. With a passion for innovation and a commitment to excellence, we offer a comprehensive suite of IT solutions designed to empower your business and drive success.</p>
                                <div className="row text-white ">
                                    <div class="container" >
                                        <div class="row">
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3' >
                                                        <img src={UpResorceImage} style={{ width: "80px", height: "80px", marginLeft:"20px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Unparallel Resource</h5>
                                                        <p>Your Gateway to Limitless Opportunities and Unmatched Support</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3'>
                                                        <img src={ProvenTrackImage} style={{ width: "80px", height: "80px", marginLeft:"20px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Proven track Record: </h5>
                                                        <p className='pe-2'>Our history of successful projects and satisfied clients speaks volumes about our capabilities</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3'>
                                                        <img src={InovationImage} style={{ width: "80px", height: "80px", marginLeft:"20px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Innovation</h5>
                                                        <p>We remain at the forefront of technological trends and innovations, oﬀering insights that keep your business ahead of the curve</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3' >
                                                        <img src={ClientCentricImage} style={{ width: "80px", height: "80px", marginLeft:"20px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Client-Centric Focus</h5>
                                                        <p>Your success is our primary concern. We work closely with you to develop strategies that align with your unique business objectives</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>



                                {/* <div className="row g-4 pt-3">
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-users fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-check fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Project Complete</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                            </div>
                            <div className="col-lg-3 align-self-center  wow fadeIn" data-wow-delay="0.3s" style={{ marginLeft: "-80px" }}>
                                <img src={WCUImage} style={{ width: "450px", height: "450px", }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/*for Moblie screen*/}
            <div className='d-lg-none'>
                <div className="container-fluid bg-dark feature-dot py-5">
                    <div className='container'>
                        <div className="row" >
                            <div className="col-lg-9 align-self-center card1 wow fadeIn" data-wow-delay="0.3s">
                                <div className="btn btn-sm border rounded-pill text-orange px-3 my-3">Why Choose Us</div>
                                <h1 className="text-white mb-4 "><span style={{ color: "orangered" }}>HexSync:</span> Your Trusted IT Partner</h1>
                                <p className="text-light mb-4 text-justify pe-3">At HexSync, we are more than just an IT service provider; we are your dedicated technology partner. With a passion for innovation and a commitment to excellence, we offer a comprehensive suite of IT solutions designed to empower your business and drive success.</p>
                                <div className="row text-white ">
                                    <div class="container" >
                                        <div class="row">
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3' >
                                                        <img src={UpResorceImage} style={{ width: "80px", height: "80px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Unparallel Resource</h5>
                                                        <p>Your Gateway to Limitless Opportunities and Unmatched Support</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3'>
                                                        <img src={ProvenTrackImage} style={{ width: "80px", height: "80px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Proven track Record: </h5>
                                                        <p>Our history of successful projects and satisfied clients speaks volumes about our capabilities</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3'>
                                                        <img src={InovationImage} style={{ width: "80px", height: "80px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Innovation</h5>
                                                        <p>We remain at the forefront of technological trends and innovations, oﬀering insights that keep your business ahead of the curve</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="row">
                                                    <div className='col-md-3' >
                                                        <img src={ClientCentricImage} style={{ width: "80px", height: "80px" }} />
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <h5 className='text-white'>Client-Centric Focus</h5>
                                                        <p>Your success is our primary concern. We work closely with you to develop strategies that align with your unique business objectives</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>



                                {/* <div className="row g-4 pt-3">
            <div className="col-sm-6">
                <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                    <i className="fa fa-users fa-3x text-white"></i>
                    <div className="ms-3">
                        <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                        <p className="text-white mb-0">Happy Clients</p>
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                    <i className="fa fa-check fa-3x text-white"></i>
                    <div className="ms-3">
                        <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                        <p className="text-white mb-0">Project Complete</p>
                    </div>
                </div>
            </div>
        </div> */}

                            </div>
                            <div className="col-lg-3 wow fadeIn" data-wow-delay="0.3s" >
                                <img src={WCUImage} style={{  height: "100%",marginLeft:"auto",marginRight:"auto" ,width:"100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs