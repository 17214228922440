import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';

const ServicesPage = () => {

    const navigate = useNavigate();
    const contact = () => navigate('/contact');
    return (
        <div>
            <Navbar />

            <div className='d-none d-lg-block'>
                <div id='header' className="container-fluid bg-dark pt-5 hero-header mb-5">
                    <div className="container pt-5">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12 align-self-center text-center text-lg-start mb-lg-5" style={{ paddingTop: "50px", paddingBottom: "250px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight">OUR <span style={{ color: "orangered" }}>SERVICES</span></h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-lg-none'>
                <div id='header' className="container-fluid bg-dark py-5 ">
                    <div className="container ">
                        <div className="row g-5 ">
                            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5" >
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">HexSync</div>
                                <h1 className="display-4 text-white mb-4 animated slideInRight">OUR <span style={{ color: "orangered" }}>SERVICES</span></h1>
                                <a onClick={contact} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid bg-light  mt-5" id='services'>
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
                            <div className="btn btn-sm border rounded-pill text-orange px-3 mb-3">Our Services</div>
                            <h1 className="mb-4">Elevate Your Vision with HexSync: Where Innovation Meets Excellence</h1>
                        </div>
                    </div>




                    <div className="col-lg-12 mt-5">
                        <div className="row g-4">
                            <div className="col-12 wow fadeIn ps-5" data-wow-delay="0.1s">
                                <div className="service-item d-flex flex-column  rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa fa-cogs fa-2x"></i>
                                    </div>
                                    <h5 className="mb-3 ">CUSTOM DEVELOPMENT</h5>
                                    <p>Our custom software development services are tailored to address your unique
                                        business needs, providing you with the tools to thrive in today's dynamic
                                        digital landscape. Explore the wide range of services we offer to elevate
                                        your business.</p>
                                    <div className='row mt-4'>
                                        <div ><p><i className='far fa-circle'></i> WebSite Development</p></div>
                                        <div ><p><i className='far fa-circle'></i> iOS & Android App Development</p></div>
                                        <div ><p><i className='far fa-circle'></i> eCommerce Solutions</p></div>
                                        <div ><p><i className='far fa-circle'></i> UI/UX Design</p></div>
                                        <div ><p><i className='far fa-circle'></i> AI & Machine Learning</p></div>
                                        <div ><p><i className='far fa-circle'></i> Blockchain & IoT</p></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 wow fadeIn ps-5" data-wow-delay="0.5s">
                                <div className="service-item d-flex flex-column  rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa fa-power-off fa-2x"></i>
                                    </div>
                                    <h5 className="mb-3 ">BUSINESS SOLUTIONS</h5>
                                    <p>HexSync specializes in streamlining your business processes through custom solutions. We analyze your workflows, identify bottlenecks, and design applications that automate tasks, reduce errors, and improve overall efficiency.</p>
                                    <div className='mt-4'><p><i className='far fa-circle'></i> Microsoft Dynamics 365</p></div>
                                    <div><p><i className='far fa-circle'></i> Salesforce</p></div>
                                    <div><p><i className='far fa-circle'></i> SAP/S4 Hana</p></div>
                                    <div><p><i className='far fa-circle'></i> Power BI</p></div>
                                    <div><p><i className='far fa-circle'></i> Complete Digital Transformation</p></div>
                                    <div><p><i className='far fa-circle'></i> CRM & ERP Enterprise Applications</p></div>
                                </div>
                            </div>

                            <div className="col-12 wow fadeIn ps-5" data-wow-delay="0.3s">
                                <div className="service-item d-flex flex-column  rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fa fa-cloud fa-2x"></i>
                                    </div>
                                    <h5 className="mb-3 ">CLOUD SOLUTION</h5>
                                    <p>HexSync empowers your organization to soar to new heights in the modern digital landscape through cloud solutions.</p>
                                    <div className='mt-4'><p><i className='far fa-circle'></i> Azure</p></div>
                                    <div><p><i className='far fa-circle'></i> AWS</p></div>
                                    <div><p><i className='far fa-circle'></i> Google Cloud</p></div>
                                    <div><p><i className='far fa-circle'></i> Cloud Integration Services</p></div>
                                    <div><p><i className='far fa-circle'></i> DevOps</p></div>
                                    <div><p><i className='far fa-circle'></i> Trainings & Support</p></div>
                                </div>
                            </div>
                            <div className="col-12 wow fadeIn ps-5" data-wow-delay="0.7s">
                                <div className="service-item d-flex flex-column  rounded">
                                    <div className="service-icon btn-square">
                                        <i className="fas fa-briefcase fa-2x"></i>
                                    </div>
                                    <h5 className="mb-3 ">BUSINESS CONSULTING</h5>
                                    <p>HexSync enables your company to rise to high altitudes by providing high skilled resources and staff whenever and wherever needed.</p>
                                    <div className='mt-4'><p><i className='far fa-circle'></i> Business Process Optimisation</p></div>
                                    <div><p><i className='far fa-circle'></i> High-Skilled Staff Augmentation</p></div>
                                    <div><p><i className='far fa-circle'></i> Managed Services</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ServicesPage