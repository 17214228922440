import React from 'react'

const NewsLetter = () => {
    return (
        <div>
            <div id='contact' className="container-fluid bg-orange newsletter "  style={{paddingTop:"130px",paddingBottom:"140px"}}>
                <div className="container">
                    <div className="row g-5 align-items-center ">
                        <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
                            <img className="img-fluid " src="img/newsletter.png" alt=".." />
                        </div>
                        <div className="col-md-7 p-5 newsletter-text wow fadeIn card1" data-wow-delay="0.5s">
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Contact Us</div>
                            <h1 className="text-white mb-4">Sync Smarter with HexSync</h1>
                            <p className="text-light"><i className="fa fa-phone-alt me-3 text-light"></i>+92 347 3994589</p>
                            <p className="text-light"><i className="fas fa-envelope me-3"></i>info@hexsync.com</p>
                            <p className="text-light"><i className="fa fa-map-marker-alt me-3"></i>B-50, Karsaz Service Road West, KDA Scheme #1, Karachi</p>
                            <div className="position-relative w-100 mt-3 mb-2">
                                <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="email"
                                    placeholder="Enter Your Email" style={{ height: "48px" }} />
                                <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    className="fa fa-paper-plane text-orange fs-4"></i></button>
                            </div>
                            <small className="text-white-50"></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter